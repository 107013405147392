import React, { useState } from 'react';
import ModalWrapper from '../../shared/modal/modalWrapper';
import StepMain from './components/StepMain';
import StepLoading from './components/StepLoading';
import StepResult from '../../shared/modal/DotationStepResult';
import i18n from '../../../libs/i18n/i18n';
import { STEP } from '../../../types/modalTypes';
var ModuleInactiveDotation = function (_a) {
    var _b;
    var data = _a.data;
    var _c = useState(STEP.START), currentStep = _c[0], setCurrentStep = _c[1];
    var _d = useState(null), rule = _d[0], setRule = _d[1];
    var _e = useState(null), reason = _e[0], setReason = _e[1];
    var _f = useState(null), errorMessage = _f[0], setErrorMessage = _f[1];
    var steps = (_b = {},
        _b[STEP.START] = (React.createElement(StepMain, { data: data, rule: rule, setRule: setRule, reason: reason, setReason: setReason, setCurrentStep: setCurrentStep })),
        _b[STEP.LOADING] = (React.createElement(StepLoading, { data: data, rule: rule, reason: reason, setCurrentStep: setCurrentStep, setErrorMessage: setErrorMessage })),
        _b[STEP.SUCCESS] = (React.createElement(StepResult, { data: data, message: i18n.t('tagActivity.inactive_success'), isSuccess: true })),
        _b[STEP.ERROR] = (React.createElement(StepResult, { data: data, message: errorMessage, isSuccess: false })),
        _b);
    return (React.createElement(ModalWrapper, { currentStep: currentStep, modalTitle: "deactivateDotation" }, steps[currentStep]));
};
export default ModuleInactiveDotation;
